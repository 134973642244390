/* eslint-disable no-unused-vars */
import { NavLink } from 'react-router-dom';
import logo from '../assets/img/valolytics.svg';
import '../css/navbar.css';
import React, { useContext } from 'react';
import { LanguageContext } from '../LanguageProvider.js';
import Flag from 'react-flagkit';

export const Navbar = () => {
    const { language, setLanguage } = useContext(LanguageContext);

    const handleLanguageChange = () => {
        if (language === 'en') { return setLanguage('de'); }
        else { return setLanguage('en'); }
      };

    return (
        <>
            <div className="navbar">
                <div className="container">
                    <NavLink to="/" className="nav-brand"><img loading="lazy" width="69" src={logo} alt="Logo"/></NavLink>
                    <nav className="nav-menu">
                        <NavLink to="https://stats.valolytics.gg" target="_blank" rel="noreferrer" className="fade nav-link">STATS</NavLink>
                        <NavLink to="https://lft.valolytics.gg" target="_blank" rel="noreferrer" className="fade nav-link">LFT</NavLink>
                        <NavLink to="https://auth.valolytics.gg" target="_blank" rel="noreferrer" className="fade nav-link">LOGIN</NavLink>
                        <div className="nav-link language-switch" onClick={handleLanguageChange}>
                            {language === 'en' ? 
                                <Flag country="GB" size={24} style={{borderRadius: '7px'}}/> :
                                <Flag country="DE" size={24} style={{borderRadius: '7px'}}/>
                            }
                        </div>
                    </nav>
                </div>
            </div>
        </>
    )
}